<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M23.7341 28.0852L23.215 26.7208H18.7956L18.2765 28.1149C18.0738 28.6587 17.9008 29.027 17.7574 29.2198C17.6141 29.4076 17.3792 29.5015 17.053 29.5015C16.7761 29.5015 16.5314 29.4002 16.3189 29.1975C16.1063 28.9948 16 28.765 16 28.5079C16 28.3596 16.0247 28.2063 16.0742 28.0481C16.1236 27.89 16.2052 27.67 16.3189 27.3882L19.0996 20.3289C19.1787 20.1262 19.2726 19.884 19.3814 19.6022C19.4951 19.3154 19.6137 19.0782 19.7373 18.8903C19.8658 18.7025 20.0314 18.5517 20.2341 18.438C20.4417 18.3193 20.6963 18.26 20.9979 18.26C21.3044 18.26 21.559 18.3193 21.7617 18.438C21.9693 18.5517 22.1349 18.7 22.2585 18.8829C22.387 19.0658 22.4933 19.2635 22.5773 19.4761C22.6663 19.6837 22.7775 19.963 22.911 20.314L25.7511 27.3289C25.9735 27.8628 26.0847 28.2508 26.0847 28.4931C26.0847 28.7452 25.9785 28.9775 25.7659 29.1901C25.5583 29.3977 25.3061 29.5015 25.0095 29.5015C24.8365 29.5015 24.6882 29.4694 24.5646 29.4051C24.441 29.3458 24.3372 29.2642 24.2532 29.1604C24.1691 29.0517 24.0777 28.8885 23.9788 28.671C23.8849 28.4486 23.8033 28.2533 23.7341 28.0852ZM19.3739 25.0672H22.6218L20.9831 20.581L19.3739 25.0672Z"
        fill="inherit"/>
    <path
        d="M27.7977 28.1594V19.5948C27.7977 19.1498 27.899 18.8162 28.1017 18.5937C28.3044 18.3712 28.5664 18.26 28.8877 18.26C29.2189 18.26 29.4859 18.3712 29.6886 18.5937C29.8962 18.8112 30 19.1449 30 19.5948V28.1594C30 28.6092 29.8962 28.9454 29.6886 29.1678C29.4859 29.3903 29.2189 29.5015 28.8877 29.5015C28.5713 29.5015 28.3093 29.3903 28.1017 29.1678C27.899 28.9404 27.7977 28.6043 27.7977 28.1594Z"
        fill="inherit"/>
    <path clip-rule="evenodd"
          d="M32.5 13H14.5C13.6716 13 13 13.6716 13 14.5V32.5C13 33.3284 13.6716 34 14.5 34H32.5C33.3284 34 34 33.3284 34 32.5V14.5C34 13.6716 33.3284 13 32.5 13ZM14.5 10.5C12.2909 10.5 10.5 12.2909 10.5 14.5V32.5C10.5 34.7091 12.2909 36.5 14.5 36.5H32.5C34.7091 36.5 36.5 34.7091 36.5 32.5V14.5C36.5 12.2909 34.7091 10.5 32.5 10.5H14.5Z"
          fill="inherit"
          fill-rule="evenodd"/>
    <path d="M15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8V11H15V8Z" fill="inherit"/>
    <path d="M20 6C20 5.44772 20.4477 5 21 5C21.5523 5 22 5.44772 22 6V11H20V6Z" fill="inherit"/>
    <path d="M25 6C25 5.44772 25.4477 5 26 5C26.5523 5 27 5.44772 27 6V11H25V6Z" fill="inherit"/>
    <path d="M30 8C30 7.44772 30.4477 7 31 7C31.5523 7 32 7.44772 32 8V11H30V8Z" fill="inherit"/>
    <path d="M32 39C32 39.5523 31.5523 40 31 40C30.4477 40 30 39.5523 30 39V36H32V39Z" fill="inherit"/>
    <path d="M27 41C27 41.5523 26.5523 42 26 42C25.4477 42 25 41.5523 25 41V36H27V41Z" fill="inherit"/>
    <path d="M22 41C22 41.5523 21.5523 42 21 42C20.4477 42 20 41.5523 20 41V36L22 36V41Z" fill="inherit"/>
    <path d="M17 39C17 39.5523 16.5523 40 16 40C15.4477 40 15 39.5523 15 39V36H17V39Z" fill="inherit"/>
    <path d="M39 15C39.5523 15 40 15.4477 40 16C40 16.5523 39.5523 17 39 17H36V15H39Z" fill="inherit"/>
    <path d="M41 20C41.5523 20 42 20.4477 42 21C42 21.5523 41.5523 22 41 22H36V20H41Z" fill="inherit"/>
    <path d="M41 25C41.5523 25 42 25.4477 42 26C42 26.5523 41.5523 27 41 27H36V25H41Z" fill="inherit"/>
    <path d="M39 30C39.5523 30 40 30.4477 40 31C40 31.5523 39.5523 32 39 32H36V30H39Z" fill="inherit"/>
    <path d="M8 32C7.44772 32 7 31.5523 7 31C7 30.4477 7.44772 30 8 30H11V32H8Z" fill="inherit"/>
    <path d="M6 27C5.44772 27 5 26.5523 5 26C5 25.4477 5.44772 25 6 25H11V27H6Z" fill="inherit"/>
    <path d="M6 22C5.44772 22 5 21.5523 5 21C5 20.4477 5.44772 20 6 20H11V22H6Z" fill="inherit"/>
    <path d="M8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15H11V17H8Z" fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-models-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
